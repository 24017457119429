import React from "react"

import Layout from "../../components/Layout"
import vft1 from "../../images/vft/Vft1_r.jpg"
import vft2 from "../../images/vft/vft2_r.jpg"
import vft3 from "../../images/vft/vft3.jpg"
import { Link } from "gatsby"


export default function Home() {
  return (
    <Layout breadcrumbs={["VFT Lab"]}>
      <div id="heading6" >
        <h1>VFT Lab</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5><Link to="/">Inicio</Link> &gt;&gt; Projetos  &gt;&gt; VFT Lab</h5>}

            <header>
              <h2>Monitorando a sustentabilidade da cadeia produtiva</h2>
            </header>

            <div style={{ display: 'flex' }}>
              <div>
                <p className="headline">Plataforma de monitoramento de risco de desmatamento na cadeia produtiva da borracha.</p>
                <p><span className="image right desktop-only"><img src={vft1} alt="" /></span>A partir de uma metodologia desenvolvida pelo Imaflora e Idesam, O VFT Lab monitora e avalia o risco de desmatamento na cadeia de fornecimento da borracha, utilizada na produção de sapatos da Vert Fair Trade.</p>
                <span className="image-page mobile-only"><img src={vft1} /></span>                                
                <p>Atualmente, a plataforma considera todas as 485 famílias que fornecem borracha para a VFT, no estado do Acre. 
                  Os grupos familiares distribuem-se ao longo de Unidades de Conservação, Assentamentos Rurais e áreas particulares.</p>
                <span className="image-page mobile-only"><img src={vft2} /></span>                                
                <p><span className="image right desktop-only"><img src={vft2} alt="" /></span>A plataforma foi desenvolvida com a capacidade de indicar áreas de maior e menor risco de desmatamento, além de gerar análises automatizadas.</p>
                <ul>
                  
                </ul>
              </div>
              <div>
            </div>
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}
